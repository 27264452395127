.button button {
    position: relative;
    display: inline-block;
    border-radius: 50px;
    /* background: #35A29F; */
    background-image: linear-gradient(to right, #97FEED , #35A29F);
    border: 3px solid #EEE2DE;
    color: #FFFFFF;
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    font-style: bold;
    padding: 15px;
    width: 250px;
    transition: all 0.5s;
    cursor: pointer;
}

.button button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.button button span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button button:hover span {
    padding-right: 20px;
}

.button button:hover span:after {
    opacity: 1;
    right: 0;
}

@media screen and (max-width: 860px) {
    .button button {
        font-size: 20px;
        padding: 10px;
        width: 200px;
    }
}