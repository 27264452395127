@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.footer {
    width: 100vw;
    height: 27vh;
    background-color: #0B666A;
    padding: 25px;
    border-radius: 20px 20px 0px 0px;
    position: relative;
    overflow-x: visible;
}

.eyebox {
    position: absolute;
    /* height: 100px; */
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 100px;
    overflow-x: visible;
}

.eye {
    width: 200px;
    height: 200px;
    background-color: #eeeeee;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: visible;
}

.eyeball {
    width: 130px;
    height: 130px;
    background-color: #0d0d0d;
    border-radius: 50%;
    position: relative;
}

.eyeline {
    width: 100%;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, 50%);
    transition: 0s;
    /* background-color: red; */
}

.eyeball2 {
    width: 30px;
    height: 30px;
    background-color: #eeeeee;
    border-radius: 50%;
}

.smilee {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

@media screen and (max-width: 860px) {
    .eyebox {
        gap: 60px;
    }

    .eye {
        width: 120px;
        height: 120px;
    }

    .eyeball {
        width: 80px;
        height: 80px;
    }

    .eyeline {
        height: 20px;
    }

    .eyeball2 {
        width: 20px;
        height: 20px;
    }
}