.projectbox {
    position: relative;
    width: 1000px;
    overflow: visible;
}

/* 3 */
.project { 
    margin-left: 10%;
    width: 450px;
    height: 350px;
    background-color: #0B666A;
    border: 5px solid #EEE2DE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    padding: 20px;
    overflow: visible;
}

/* 1 */
/* .project:nth-child(1) {
    background-color: #F8F988;
} */

.ptitle {
    font-weight: 600;
    font-size: 25px;
    padding: 10px;
    text-align: center;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
}

.pdesc {
    font-size: 1.1rem;
    text-align: justify;
    text-justify: inter-word;
    opacity: 80%;
    padding: 10px;
    color: #ffffff;
    padding-top: 20px;
}

.ptitle::selection {
    color: #000;
}

.pdesc::selection {
    color: #000;
}

.plink {
    padding-top: 20px;
    overflow: visible;
}

.plink button{
    padding: 20px;
    width: 150px;
    background: #ffffff;
    cursor: pointer;
    color: #0c1229;
    text-align: center;
    font-size: 1.1rem;
    padding: 10px;
    border: 2px solid #EEE2DE;
    transition: 0s;
    overflow: visible;
}

.plink button:hover {
    transform: rotate(-10deg);
    overflow: visible;
}

/* 4 */
.projectt {
    margin-left: 45%;
    width: 450px;
    height: 350px;
    background-color: #35A29F;
    border: 5px solid #EEE2DE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

/* 2 */
/* .projectt:nth-child(3) {
    background-color: #a76bdc;
} */

.line2 {
    position: relative;
    width: 5px;
    height: 50px;
    background-color: #EEE2DE;
    left: 50%;
    transform: translate(-50%);
}

@media screen and (max-width: 860px) {
    .projectbox {
        width: 480px;
    }
    
    .project {
        width: 300px;
        height: 250px;
        margin-left: 10%;
        padding: 5px;
    }
    
    .projectt {
        margin-left: 27%;
        width: 300px;
        height: 250px;
        padding: 5px;
    }
    
    .ptitle {
        font-size: 20px;
    }
    
    .pdesc {
        font-size: 13px;
    }
    
    .plink {
        padding-top: 5px;
    }
    
    .plink button {
        font-size: 12px;
        width: 100px;
        padding: 5px;
    }

    .line2 {
        height: 30px;
    }
}

.hover-image {
    width: auto;
    height: 200px;
    pointer-events: none; 
    z-index: 1000; 
    transition-duration: 0s;
}
  