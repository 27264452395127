.titlebox {
    position: relative;
    width: 950px;
    height: 330px;
    background-color: #fff;
    border: 5px solid #EEE2DE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    position: relative;
    font-family: "Metrophobic", sans-serif;
    font-weight: 800;
    font-size: 110px;
    color: #151b22;
    padding: 20px;
    padding-top: 10px;
    background-color: #fff;
    text-align: center;
}

.subtitle {
    position: relative;
    font-size: 37px;
    color: #151b22;
    font-weight: 700;
    padding: 20px;
    padding-top: 10px;
    background-color: #fff;
    text-align: center;
}

@media screen and (max-width: 860px) {
    .titlebox {
        width: 350px;
        height: 180px;
    }
    
    .title {
        font-size: 39px;
        padding: 10px;
    }
    
    .subtitle {
        font-size: 15px;
        padding: 10px;
    }
}