@import url('https://fonts.googleapis.com/css2?family=Metrophobic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Metrophobic", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: 1s;
    /* background-color: #090a0f; */
    scroll-behavior: smooth;
    overflow-x: hidden;
}

*::selection {
    background: #97FEED;
}

::-webkit-scrollbar {
    width: 0px;
}

.cv {
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
}

.me img {
    margin-top: 20%;
    position: relative;
    width: 180px;
    border-radius: 100%;
    margin-bottom: -3.5px;
    cursor: pointer;
}

.randomthings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.randomthings img {
    position: relative;
    width: 50%;
}

.head {
    position: relative;
    border: 5px solid #EEE2DE;
    padding: 15px 40px;
    font-size: 30px;
    color: #EEE2DE;
    font-family: "Rowdies", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* background-color: #071952; */
    background-image: linear-gradient(to top, #071952 , #0d0d0d);
    border-radius: 50px;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #151b22; */
}

.head::selection {
    color: #0d0d0d;
}

@media screen and (max-width: 860px) {

    .cv {
        overflow-x: hidden;
        padding: 20px;
    }

    .me {
        margin-top: 30%;
    }

    .me img {
        width: 150px;
    }

    .head {
        font-size: 18px;
        padding: 10px 20px;
        -webkit-text-stroke-width: 0.5px;
    }

    .randomthings img{
        width: 80%;
    }

}

footer {
    overflow: visible;
}
