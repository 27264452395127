.app {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0d0d0d;
    padding: 2rem;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.bggrad {
    position: absolute;
    width: 800px;
    height: 800px;
    background-color: #071952;
    border-radius: 50%;
    opacity: 50%;
    filter: blur(100px);
    pointer-events: none;
}

.heading {
    font-size: 1.5rem;
    z-index: 1;
}

.heading h1::selection {
    color: #0d0d0d;
}

.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2.5rem;
    width: 95vw;
    margin: 1rem;
    margin-top: 2rem;
    z-index: 1;
    overflow: visible;
}

.card {
    width: 100%;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    background-color: #2C2C2C;
    border: 1px dotted #626262;
    z-index: 1;
    transition: opacity 1s ease;
    overflow: visible;
}

.card:hover {
    border: 1px solid #626262;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
}

.cardhead {
    overflow: visible;
}

.cardhead img {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.cardhead video {
    transition: transform 0.5s ease;
}

.cardhead img:hover {
    transform: scale(97%) translateY(-20px);
}

.cardhead video:hover {
    transform: scale(97%) translateY(-20px);
}

.cardfoot {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.95rem;
}

.name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
}

.name p {
    font-size: 1.2rem;
}

.desc {
    margin-top: 0.5rem;
    color: #fff;
    opacity: 80%;
}

.skills {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.skill {
    background-color: #969696;
    color: #fff;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    border-radius: 5px;
}

.btn {
    background-color: #97FEED;
    color: #0d0d0d;
    padding: 0.5rem 1.5rem;
    border-radius: 50px;
    text-decoration: none;
    transition: transform 0s ease;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.btn.hover-style-1:hover {
    transform: rotate(-10deg);
}

.btn.hover-style-2:hover {
    transform: rotate(10deg);
}

.name p::selection {
    color: #0d0d0d;
}

.desc p::selection {
    color: #0d0d0d;
}

.skills span::selection {
    color: #0d0d0d;
}

.more {
    font-size: 2rem;
    text-align: center;
    padding: 60% 0px;
}

.more::selection {
    color: #0d0d0d;
}

.popup {
    position: fixed;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 100%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 40px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 2px dotted white ;
    user-select: none;
}

.popuptext {
    text-align: center;
    font-size: 1.2rem;
    width: 350px;
}

@media (max-width: 600px) {
    .popuptext {
        font-size: 0.9rem;
        width: 200px;
    }
    .more {
        font-size: 1.5rem;
        padding: 55% 0px;
    }
}

/* .container .card:nth-child(11) .cardhead img:hover {
    opacity: 0.3;
} */