.socialbox {
    position: relative;
    width: 700px;
    height: fit-content;
    /* border: 5px solid #EEE2DE; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social {
    border: 5px solid #EEE2DE;
    background-color: transparent;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
}

.social img {
    width: 70px;
    transition: 0s;
    border-radius: 100%;
}

.social img:hover {
    transform: rotate(-20deg);
}

@media screen and (max-width: 860px) {
    .socialbox {
        width: 380px;
        height: fit-content;
    }
    .social img {
        width: 30px;
    }
}