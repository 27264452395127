.designbox {
    position: relative;
    width: 75%;
    padding: 10px;
    border: 5px solid #EEE2DE;
}

.masonry-grid {
    display: flex;
    margin-left: -10px;
    width: auto;
}

.masonry-grid_column {
    padding-left: 10px;
    background-clip: padding-box;
}

.design {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.design img {
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: cover;
    border-radius: 5px;
    border: 2px solid #EEE2DE;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px; */
    transition: transform 0.3s;
}

.design img:hover {
    transform: scale(0.95);
}

@media screen and (max-width: 860px) {
    .designbox {
        width: 100%;
    }
}