.blank {
    position: relative;
    width: 10px;
    height: 150px;
    /* border: 5px solid #EEE2DE; */
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 860px) {
    .blank {
        height: 100px;
    }
}