.contact {
    position: relative;
    width: 700px;
    height: fit-content;
    /* border: 5px solid #EEE2DE; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: visible;
}

.email {
    overflow: visible;
}

.email button {
    padding: 15px 40px;
    font-size: 20px;
    cursor: pointer;
    background-image: linear-gradient(to right, #97FEED , #35A29F);
    border: 5px solid #EEE2DE;
    border-radius: 50px;
    transition: 0.5s;
    overflow: visible;
}

.contactext {
    padding: 20px;
    font-size: 20px;
    color: #fff;
    /* background-color: #FFFFFF;
    border-radius: 50px; */
}

.contactext::selection {
    color: black;
}

.email button:hover {
    /* color: white; */
    transform: scale(1.1);
}

@media screen and (max-width: 860px) {
    .contact {
        width: 400px;
        height: fit-content;
    }
    .contactext {
        font-size: 16px;
    }
    .email button {
        font-size: 16px;
        padding: 15px 40px;
    }
}